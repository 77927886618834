import React, { useState } from 'react';
import styled from 'styled-components';
import news from '../assets/images/news.jpg';
import NavPages from '../components/NavPages';
import SEO from '../components/SEO';

const BontPilotPageStyled = styled.div`
  width: 100%;
  .bp-header {
    background-image: linear-gradient(
        to bottom,
        var(--whiteTrans),
        var(--whiteTrans)
      ),
      url(${news});
  }
`;

export default function BontPilotPage() {
  return (
    <>
      <SEO title="Join our Bont Pilot program" />
      <BontPilotPageStyled>
        <div className="wrapper">
          <section className="page-header bp-header">
            <div>
              <h2>Bont Pilot Program</h2>
            </div>
          </section>
          <div className="klaviyo-form-Y2mW68" />
        </div>
        <NavPages />
      </BontPilotPageStyled>
    </>
  );
}
